/* ------------------------------------------------------- MIXINS ------------------------------------------------------- */
@mixin flex() {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@mixin flex_inline() {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
@mixin align-items($argument){
  -webkit-box-align: $argument;
  -webkit-align-items: $argument;
  -ms-flex-align: $argument;
  align-items: $argument;
}
@mixin flex-column{
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@mixin flex-row{
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
-webkit-flex-direction: row;
-ms-flex-direction: row;
flex-direction: row;
}
@mixin box() {
  display: -webkit-box;
  display: -ms-flexbox;
  display: box;
}
@mixin clearfix() {
  &:before,
  &:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }
  &:after {
    clear: both;
  }
}
@mixin opacity($opacity) {
  opacity: $opacity; /* All modern browsers */
  $opacityIE: $opacity * 100;
  filter: alpha(opacity=$opacityIE); /* For IE5~IE9 */
}
@mixin ellipsis($line-cnt, $line-height) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line-cnt;
  -webkit-box-orient: vertical;
  word-wrap: break-word; 
  line-height: $line-height;
  height: $line-height * $line-cnt;
}
@mixin contents-title() {
  color: $color-description;
  font-size: max(1.0417vw, 20px);
  font-weight: 600;
}
@mixin shadow() {
  -webkit-box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
     -moz-box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
          box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
@mixin shadow-box() {
  -webkit-box-shadow: 6px 10px 20px 0px rgba(0, 0, 0, 0.1);
     -moz-box-shadow: 6px 10px 20px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 6px 10px 20px 0px rgba(0, 0, 0, 0.1);
}