/* ------------------------------------------------------- HOME ------------------------------------------------------- */
@media (max-width: 1280px) {
  $section-height: 500px;
  * {
    font-size: 13px;
  }
  .aimble-layout {
    .container-fluid {
      .footer {
        padding: $gutter-lg $gutter-xl;
      }
    }
  }
  .scroll-top {
    &.MuiFab-root {
      width: 54px;
      height: 54px;
      .MuiSvgIcon-root {
        font-size: 28px;
      }
    }
  }
  .header {
    h1 {
      .MuiLink-root {
        width: 140px;
      }
    }
    .nav {
      .menu-list {
        .menu {
          width: 160px;
          .MuiListItemButton-root {
            width: 160px;
          }
          .MuiTypography-root {
            font-size: 15px;
          }
        }
      }
    }
  }
  .footer {
    &-nav {
      .MuiList-root {
        .MuiListItem-root {
          &:last-child {
            .MuiListItemButton-root {
              width: 100px;
            }
          }
          .MuiListItemButton-root {
            margin-left: 0;
          }
        }
      }
    }
  }
  .main-carousel {
    height: 600px;
    .carousel {
      &-txt {
        height: 600px;
        .tit {
          font-size: 48px;
          padding-top: $gutter-xl;
        }
        .subtit {
          font-size: 38px;
          margin: $gutter-lg 0 $gutter-sm;
        }
        .desc {
          font-size: 16px;
        }
      }
    }
  }
  .home {
    .headline {
      .tit {
        font-size: 20px;
      }
      .desc {
        font-size: 32px;
        margin-top: $gutter-md;
        &.lg {
          font-size: 40px;
        }
        &.xl {
          font-size: 42px;
        }
      }
      & + .shortcut {
        margin-top: $gutter-xl;
      }
    }
    .shortcut {
      width: 180px;
      font-size: 14px;
    }
    &-technology {
      padding: $gutter-xxl;
      &-container {
        margin-top: -180px;
      }
    }
    &-news {
      padding: $gutter-xxl 0 $gutter-xxl $gutter-xxl;
      &-header {
        width: 200px;
      }
      &-container {
        width: calc(100vw - 200px - #{$gutter-xxl});
        .swiper-newsroom {
          .swiper-slide {
            .txt-wrap {
              padding: $gutter-xl $gutter-lg $gutter-lg;
              .tit {
                font-size: 22px;
                margin-top: $gutter-md;
                line-height: 1.5;
              }
              .desc {
                font-size: 16px;
                margin: $gutter-lg 0;
              }
              .date {
                margin-top: $gutter-md;
              }
            }
            .btn {
              height: 80px;
              padding: $gutter-lg;
              span {
                font-size: 17px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
    &-partners {
      height: $section-height;
      .partners {
        &-img {
          width: 300px;
        }
      }
    }
    &-contact {
      height: $section-height;
      background-attachment: inherit;
    }
  }
}
@media (max-width: 1024px) {
  $header-height: 80px;
  .header {
    .MuiToolbar-root {
      align-items: center;
      padding: 0 $gutter-xl;
    }
    h1 {
      .MuiLink-root {
        width: 130px;
        height: $header-height;
      }
    }
    .nav {
      .menu-list {
        display: none;
        .menu {
          &-levels-1 {
            height: $header-height;
          }
        }
      }
      .btn-menu {
        display: block;
      }
      .language {
        height: $header-height;
      }
    }
  }
  .footer {
    &-nav {
      display: none;
    }
  }
  .main-carousel {
    height: 500px;
    .carousel {
      &-txt {
        height: 500px;
        .tit {
          font-size: 40px;
          padding-top: $gutter-xl;
        }
        .subtit {
          font-size: 32px;
          margin: $gutter-lg 0 $gutter-sm;
        }
        .desc {
          max-width: 70%;
          font-size: 15px;
        }
      }
    }
  }
  .home {
    .headline {
      .desc {
        &.lg {
          font-size: 32px;
        }
        &.xl {
          font-size: 35px;
        }
      }
    }
    &-technology {
      &-container {
        .img {
          width: 80%;
        }
      }
    }
    &-news {
      &-container {
        .swiper-newsroom {
          .swiper-slide {
            .txt-wrap {
              padding: $gutter-xl $gutter-lg $gutter-lg;
              .tit {
                font-size: 22px;
                margin-top: $gutter-md;
              }
              .desc {
                font-size: 15px;
                margin: $gutter-lg 0;
              }
              .date {
                margin-top: $gutter-lg;
              }
            }
            .btn {
              height: 80px;
              padding: $gutter-lg;
              span {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
    &-partners {
      .headline {
        padding: 0 $gutter-xxl $gutter-xxl;
      }
    }
    &-contact {
      .headline {
        padding: 0 $gutter-xxl;
      }
    }
  }
}
@media (max-width: 930px) {
  $section-height: 350px;
  .scroll-top {
    &.MuiFab-root {
      width: 48px;
      height: 48px;
      .MuiSvgIcon-root {
        font-size: 24px;
      }
    }
  }
  .main-carousel {
    height: 400px;
    .carousel {
      &-txt {
        height: 400px;
        .tit {
          font-size: 30px;
        }
        .subtit {
          font-size: 24px;
          margin: 12px 0 6px;
        }
        .desc {
          font-size: 12px;
        }
      }
      &-img {
        width: 1000px;
      }
    }
  }
  .home {
    .headline {
      .tit {
        font-size: 17px;
      }
      .desc {
        font-size: 25px;
        letter-spacing: 0;
        line-height: 1.4;
        margin-top: $gutter-sm;
        &.lg {
          font-size: 27px;
          font-weight: 600;
        }
        &.xl {
          font-size: 30px;
          font-weight: 700;
        }
      }
      & + .shortcut {
        margin-top: 20px;
      }
    }
    .shortcut {
      width: 140px;
      font-size: 12px;
      padding: $gutter-sm $gutter-md;
      .MuiSvgIcon-root {
        font-size: 16px;
      }
    }
    &-technology {
      &-container {
        margin-top: -100px;
        .img {
          width: 100%;
        }
      }
    }
    &-news {
      background-size: 1500px auto;
      &-container {
        .swiper-newsroom {
          .swiper-slide {
            .txt-wrap {
              .tit {
                font-size: 18px;
              }
              .desc {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    &-partners {
      height: $section-height;
      .headline {
        padding: 0 $gutter-xl $gutter-lg;
      }
      .partners {
        &-img {
          width: 250px;
        }
      }
    }
    &-contact {
      height: $section-height;
    }
  }
  .news-detail {
    .contents-container {
      .view {
        &-controls {
          margin-top: $gutter-md;
          .MuiButton-colorInherit {
            font-size: 12px;
          }
          .MuiButton-outlined {
            width: 140px;
            font-size: 12px;
            padding: $gutter-sm $gutter-md;
          }
        }
      }
    }
  }
}
@media (max-width: 720px) {
  * {
    font-size: 12px;
  }
  .home {
    .headline {
      .tit {
        font-size: 17px;
      }
      .desc {
        font-size: 22px;
        letter-spacing: 0;
        line-height: 1.3;
        &.lg {
          font-size: 23px;
          font-weight: 500;
        }
        &.xl {
          font-size: 24px;
          font-weight: 700;
        }
      }
    }
    &-technology {
      padding: $gutter-lg;
      &-container {
        margin-top: 0;
        .img {
          width: 100%;
        }
      }
    }
    &-news {
      flex-direction: column;
      padding: $gutter-lg;
      &-header {
        width: 100%;
        br {
          display: none;
        }
      }
      &-container {
        width: 100%;
        margin-top: $gutter-lg;
      }
    }
    &-partners {
      .headline {
        padding: 0 $gutter-lg;
      }
    }
    &-contact {
      background-size: 1000px auto;
      .headline {
        padding: 0 $gutter-lg;
      }
      br {
        display: none;
      }
    }
  }
}
@media (max-width: 500px) {
  .main-carousel {
    .carousel {
      &-txt {
        padding: 24px;
        .tit {
          font-size: 26px;
          margin-bottom: 12px;
        }
        .subtit {
          display: none;
        }
        .desc {
          max-width: inherit;
        }
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }
}
@media (max-width: 430px) {
  $footer-height: 70px;
  .aimble-layout {
    .header {
      h1 {
        .MuiLink-root {
          width: 130px;
        }
      }
      .nav {
        .menu-list {
          display: none;
        }
        .btn-menu {
          display: block;
        }
      }
    }
    .container-fluid {
      .container {
        min-height: calc(100vh - #{$footer-height});
      }
      .footer {
        justify-content: center;
        min-height: $footer-height;
        height: $footer-height;
        .footer-logo {
          display: none;
        }
        &-info {
          &-company,
          &-address {
            display: none;
          }
          &-copy {
            padding-top: 0;
          }
        }
      }
    }
  }
  .home {
    &-news {
      &-container {
        padding: 0;
      }
    }
  }
}
/* ------------------------------------------------------- PAGE ------------------------------------------------------- */
@media (max-width: 1440px) {
  .company {
    .team {
      &-info {
        &-img {
          width: 240px;
          height: 240px;
        }
      }
    }
  }
  .press-release {
    .contents-container {
      .card-wrap {
        .MuiCard-root {
          width: calc(33.33% - #{$gutter-md}* 2);
        }
      }
    }
  }
  .contact-us {
    .contents-container {
      .inquiry-list {
        .MuiCard-root {
          width: 200px;
          height: 200px;
          .MuiCardContent-root {
            .tit {
              font-size: 24px;
            }
            .desc {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1280px) {
  .contents {
    &-header {
      .inner {
        .tit {
          font-size: 40px;
          strong {
            font-size: 40px;
          }
          span {
            font-size: 38px;
          }
        }
      }
    }
    &-container {
      .tit {
        font-size: 36px;
        small {
          font-size: 20px;
        }
      }
      .desc {
        strong {
          font-size: 28px;
          & + small {
            margin: $gutter-sm 0 $gutter-xl;
          }
        }
        small {
          font-size: 20px;
        }
      }
    }
  }
  .business {
    .contents-container {
      .tit {
        font-size: 32px;
        margin-top: 0;
      }
      .desc {
        font-size: 14px;
      }
      dl {
        dt {
          font-size: 16px;
          & + dd {
            margin-top: $gutter-md;
          }
        }
        dd {
          font-size: 14px;
          margin: $gutter-sm 0;
          padding-left: 20px;
          &::before {
            width: 4px;
            height: 4px;
            margin: 3px 6px;
            margin-left: -10px;
          }
          & + dt {
            margin-top: $gutter-lg;
          }
        }
      }
      &.platform {
        .article-wrap {
          margin: $gutter-xl 0;
          &.col {
            .article-img {
              width: 90%;
              margin: 0 auto;
              & + .desc {
                margin-top: $gutter-lg;
              }
            }
          }
          .article-img {
            width: 45%;
            margin: $gutter-xl $gutter-xl $gutter-xl 0;
          }
          .article {
            margin-left: $gutter-sm;
            &-tit {
              font-size: 18px;
              line-height: 1.4;
              margin: $gutter-md 0;
            }
          }
        }
      }
      &.service {
        .article-wrap {
          .article {
            height: 180px;
            .img {
              width: 150px;
            }
            .service-steps {
              dt {
                font-size: 20px;
                & + dd {
                  margin-top: $gutter-md;
                }
              }
            }
          }
        }
      }
    }
  }
  .notice {
    .contents-container {
      .MuiListItemButton-root {
        padding: $gutter-lg 0;
      }
      .date {
        width: 120px;
        span {
          font-size: 15px;
        }
      }
      .txt-wrap {
        padding: 0 $gutter-md 0 $gutter-sm;
        .tit {
          strong {
            font-size: 20px;
          }
        }
      }
      .MuiPagination-root {
        margin-top: $gutter-md;
      }
    }
  }
  .press-release {
    .contents-container {
      .card-wrap {
        .MuiCard-root {
          width: calc(33.3% - #{$gutter-sm}* 2);
          height: 430px;
          margin: $gutter-sm !important;
          .MuiCardActionArea-root {
            .thumb {
              height: 220px;
            }
            .txt-wrap {
              padding: $gutter-lg;
              .tit {
                font-size: 20px;
                margin: $gutter-md 0;
              }
              .desc {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
  .contact-us {
    .contents-container {
      .inquiry-list {
        .MuiCard-root {
          margin: $gutter-sm;
        }
      }
      .address-list {
        dt {
          font-size: 28px;
        }
        dd {
          font-size: 20px;
          line-height: 1.4;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .contents {
    .inner {
      padding: $gutter-xl;
    }
  }
  .company {
    .MuiTimeline-root {
      padding: 0;
      .MuiTimelineSeparator-root {
        margin: 0 $gutter-xl;
      }
      .MuiTimelineOppositeContent-root {
        width: 150px;
        min-width: 150px;
        padding: 0;
        .year {
          font-size: 80px;
        }
      }
      .MuiTimelineContent-root {
        padding: 0;
        .date {
          font-size: 14px;
          &:first-child {
            padding-top: $gutter-xl;
          }
        }
        .txt {
          font-size: 18px;
          padding-bottom: $gutter-md;
        }
      }
    }
  }
  .business {
    .contents-container {
      .tit {
        margin-bottom: $gutter-lg;
      }
      &.service {
        .article-wrap {
          flex-direction: column;
          .article {
            width: calc(100% - #{$gutter-md}* 2);
            .img {
              width: 120px;
            }
          }
        }
      }
    }
  }
  .news-detail {
    .contents-container {
      .view {
        &-header {
          padding: $gutter-md;
          .tit {
            strong {
              font-size: 20px;
            }
            & + .MuiStack-root {
              margin-top: $gutter-sm;
            }
          }
        }
        &-content {
          padding: $gutter-md;
          .desc {
            font-size: 15px;
          }
          .MuiTypography-caption {
            font-size: 13px;
          }
        }
        &-controls {
          .MuiButton-colorInherit {
            font-size: 14px;
          }
          .MuiButton-outlined {
            width: 160px;
            padding: $gutter-sm $gutter-md;
          }
        }
      }
    }
  }
}
@media (max-width: 930px) {
  .contents {
    &-header {
      height: 300px;
      .inner {
        .category {
          font-size: 14px;
        }
        .tit {
          font-size: 34px;
          strong {
            font-size: 34px;
          }
          span {
            font-size: 34px;
          }
        }
      }
    }
    &-container {
      .tit {
        font-size: 28px;
      }
      .desc {
        strong {
          font-size: 20px;
        }
        small {
          font-size: 18px;
        }
      }
    }
  }
  .company {
    .contents-container {
      .tit {
        margin: $gutter-sm 0 $gutter-xl;
      }
      .sub-tit {
        font-size: 17px;
        margin-top: 0;
      }
      .desc {
        margin-bottom: 0;
        strong {
          font-size: 15px;
        }
        span {
          font-size: 13px;
        }
      }
    }
    .team {
      & + .team {
        margin-top: $gutter-lg;
      }
      &-info {
        &-img {
          width: 100px;
          height: 100px;
          border-radius: $radius-md;
          margin-right: $gutter-md;
        }
        &-name {
          font-size: 24px;
          span {
            font-size: 13px;
          }
        }
        &-list {
          li {
            font-size: 13px;
            margin-top: $gutter-sm;
            font-weight: 300;
            strong {
              font-size: 15px;
            }
          }
        }
      }
      dl {
        dt {
          font-size: 18px;
        }
        dd {
          font-size: 15px;
          line-height: 1.6;
        }
      }
    }
  }
  .press-release {
    .contents-container {
      .card-wrap {
        .MuiCard-root {
          height: 330px;
          .MuiCardActionArea-root {
            .thumb {
              height: 160px;
            }
            .txt-wrap {
              .tit {
                font-size: 15px;
                margin-bottom: $gutter-sm;
              }
              .desc {
                font-size: 13px;
              }
              .date {
                font-size: 11px;
                margin-top: $gutter-md;
              }
            }
          }
        }
      }
    }
  }
  .contact-us {
    .contents-container {
      .email {
        padding-bottom: 0;
        align-items: normal;
        strong,
        span {
          font-size: 15px;
        }
      }
      .inquiry-list {
        .MuiCard-root {
          display: none;
        }
      }
      .address-list {
        dt {
          font-size: 20px;
          font-weight: 700;
          padding-top: 16px;
          padding-bottom: 8px;
        }
        dd {
          font-size: 18px;
        }
        .iframe-wrap {
          height: 360px;
        }
      }
    }
  }
}
@media (max-width: 720px) {
  .company {
    .team {
      flex-direction: column;
      &-info {
        width: 100%;
        margin: 0;
        & + .team-info {
          margin-top: $gutter-md;
        }
      }
      &:last-of-type {
        flex-direction: row;
        flex-wrap: wrap;
        dl {
          width: calc(50% - 12px);
          margin: 0;
        }
      }
    }
  }
  .press-release {
    .contents-container {
      .card-wrap {
        .MuiCard-root {
          width: calc(50% - #{$gutter-sm}* 2);
        }
      }
    }
  }
}
@media (max-width: 570px) {
  .company {
    .MuiTimeline-root {
      .MuiTimelineSeparator-root {
        margin: 0 $gutter-md;
      }
      .MuiTimelineOppositeContent-root {
        flex-grow: 0;
        text-align: left;
        min-width: unset;
        .year {
          font-size: 40px;
        }
      }
    }
  }
  .business {
    .contents-container {
      .tit {
        font-size: 24px;
      }
      .desc {
        font-size: 14px;
      }
      &.platform {
        .article-wrap {
          flex-direction: column;
          .article-img {
            width: 100%;
            margin: 0 0 $gutter-md;
          }
          .article {
            margin-left: 0;
          }
        }
      }
      &.service {
        .article-wrap {
          .article {
            width: 100%;
            margin: 0;
            .img {
              width: 100px;
              margin-right: $gutter-md;
            }
            .service-steps {
              dt {
                font-size: 17px;
                & + dd {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
  }
  .notice {
    .contents-container {
      .MuiListItemButton-root {
        padding: $gutter-md 0;
      }
      .date {
        display: none;
      }
      .txt-wrap {
        padding: 0 $gutter-sm 0 $gutter-xs;
        .tit {
          margin-top: 0;
          .MuiChip-root {
            & + strong {
              padding-left: $gutter-xs;
            }
          }
          strong {
            font-size: 16px;
          }
        }
        .desc {
          font-size: 14px;
          margin-top: $gutter-xs;
        }
      }
      .MuiPagination-root {
        .MuiPaginationItem-root {
          min-width: 28px;
          height: 28px;
          padding: $gutter-xs;
          margin: 0;
        }
      }
    }
  }
  .news-detail {
    .contents-container {
      .view {
        &-header {
          padding: $gutter-md 0;
          .tit {
            strong {
              font-size: 17px;
            }
          }
          .desc * {
            font-size: 13px;
          }
        }
        &-content {
          padding: $gutter-md 0;
          .desc {
            font-size: 13px;
          }
          .MuiTypography-caption {
            font-size: 13px;
          }
        }
        &-controls {
          .MuiButton-colorInherit {
            font-size: 12px;
          }
          .MuiButton-outlined {
            width: 140px;
          }
        }
      }
    }
  }
}
@media (max-width: 430px) {
  .press-release {
    .contents-container {
      .card-wrap {
        .MuiCard-root {
          width: calc(100% - #{$gutter-sm}* 2);
        }
      }
    }
  }
}
/* ------------------------------------------------------- HOME > NAV ------------------------------------------------------- */
@media (max-width: 1024px) {
  $header-height: 50px;
  .MuiDrawer-root {
    &.nav-m {
      display: block;
      .MuiPaper-root {
        background-color: rgba($color-white, 0.25);
        backdrop-filter: blur(20px);
        opacity: 1;
        .menu-list {
          @include flex;
          flex-wrap: wrap;
          align-items: flex-start;
          .menu {
            @include flex;
            flex-direction: column;
            width: 25%;
            .sub-menu,
            .MuiListItemButton-root {
              width: 100%;
              padding: 0;
            }
            .MuiTypography-root {
              color: rgba($color-white, 0.65);
              font-size: max(0.8333vw, 16px);
              letter-spacing: 0.075rem;
              text-align: center;
            }
            &-levels-1 {
              height: $header-height;
              border-bottom: 2px solid rgba($color-white, 0.65);
              &.Mui-selected {
                background-color: transparent;
                border-bottom: 2px solid rgba($color-white, 1);
                & + .sub-menu {
                  background-color: transparent;
                }
                .MuiTypography-root {
                  color: rgba($color-white, 1);
                }
              }
              .MuiTypography-root {
                font-weight: 700;
                text-transform: uppercase;
              }
            }
            &-levels-2 {
              height: $header-height;
              &.Mui-selected {
                background-color: transparent;
                .MuiTypography-root {
                  color: rgba($color-white, 1);
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 720px) {
  .MuiDrawer-root {
    &.nav-m {
      .MuiPaper-root {
        .menu-list {
          .menu {
            width: 50%;
          }
        }
      }
    }
  }
}
