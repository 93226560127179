html,body{ width: 100%; }
body,div,h1,h2,h3,h4,h5,h6,ol,ul,li,dl,dt,dd,a,img,p,span,
form,fieldset,legend,label,input,select,button,
table,caption,colgroup,col,thead,tbody,tr,th,td,address,tfoot,
header,nav,footer,section,article,mark,figure,figcaption,time,aside,small,blockquote { margin: 0; padding: 0; }
html,header,nav,footer,section,article,figure,figcaption,aside,small { display: block; }
body {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	color: #000;
	font-family: $base-font-family;
	font-size: max(0.7292vw, 14px);
	font-weight: normal;
	line-height: 1.2;
	margin-left: auto;
	margin-right: auto;
	word-break: keep-all;
	-ms-word-break: keep-all;
}
* { font-size: max(0.7292vw, 14px); box-sizing: border-box; font-family: $base-font-family; }
h1, h2, h3, h4, h5, h6 { 
	font-family: $base-font-family;
	margin: 0; 
}
ul, ol { list-style: none; }
img, fieldset, input, button { border: none; }
table { border-collapse: collapse; border-spacing: 0; }
a, a:link, a:visited, a:hover, a:active { text-decoration: none; cursor: pointer; }
a:focus { outline: none !important; }
button, img { vertical-align: middle; }
.skip, caption {
	width: 0.0521vw;
	height: 0.0521vw; 
	overflow: hidden; 
	visibility: hidden; 
	line-height: 0; 
	font-size: 0; 
	position: absolute; 
	left: -52.0313vw;
}
sup {
  vertical-align: super;
	font-size: smaller;
}
em { 
	font-style: normal;
	font-weight: 600; 
}
.required { color: $color-error !important; }
.red { color: $color-error !important; }
.blue { color: $color-blue !important; }