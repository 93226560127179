/* ------------------------------------------------------- FONT ------------------------------------------------------- */
@import "@fontsource/noto-sans-kr";
@import "@fontsource/noto-sans-kr/200.css";
@import "@fontsource/noto-sans-kr/300.css";
@import "@fontsource/noto-sans-kr/400.css";
@import "@fontsource/noto-sans-kr/500.css";
@import "@fontsource/noto-sans-kr/600.css";
@import "@fontsource/noto-sans-kr/700.css";
@import "@fontsource/noto-sans-kr/800.css";

@import "@fontsource/noto-sans";
@import "@fontsource/noto-sans/200.css";
@import "@fontsource/noto-sans/300.css";
@import "@fontsource/noto-sans/400.css";
@import "@fontsource/noto-sans/500.css";
@import "@fontsource/noto-sans/600.css";
@import "@fontsource/noto-sans/700.css";
@import "@fontsource/noto-sans/800.css";

/* 영문과 특수문자만 Lexend를 사용 */
@font-face {
  font-family: "Lexend", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");
  unicode-range: U+0020-007E;
  font-style: normal;
}
$base-font-family: "Lexend", "Noto Sans KR", sans-serif !important;

/* ------------------------------------------------------- COLOR ------------------------------------------------------- */
$primary: #ec6786;
$primary-light: #f291a8;
$primary-dark: #e03455;
$secondary: #7879f1;
$secondary-light: #8486ff;
$secondary-dark: #4a45bd;

$color-white: #ffffff;
$color-light-gray: #cccccc;
$color-gray: #999999;
$color-dark-gray: #222222;
$color-title: #000;
$color-description: #7e7c77;
$color-tooltip: #393690;
$color-background: #eaeaea;
$color-error: #ea1917;
$color-blue: #0057ff;

$bg-light-gray: #f6f6f6;
$bg-dark-gray: rgba(0, 0, 0, 0.1);

/* ------------------------------------------------------- LAYOUT ------------------------------------------------------- */
$header-height: max(5.2083vw, 100px);
$footer-height: max(11.9792vw, 230px);

$radius-sm: max(0.2083vw, 4px);
$radius-md: max(0.625vw, 12px);
$radius-lg: max(0.9375, 18px);
$radius-xl: max(3.125vw, 60px);

$gutter-xs: max(0.2083vw, 4px);
$gutter-sm: max(0.4167vw, 8px);
$gutter-md: max(0.8333vw, 16px);
$gutter-lg: max(1.25vw, 24px);
$gutter-xl: max(1.6667vw, 32px);
$gutter-xxl: max(2.5vw, 48px);
$gutter-xxxl: max(6.25vw, 120px);
$radius-sm: max(0.2083vw, 4px);
$radius-md: max(0.625vw, 12px);

$radius-mui: max(0.8333vw, 16px);
$gutter-mui-sm: max(0.4167vw, 8px);
$gutter-mui-md: max(0.8333vw, 16px);

$border-width: max(0.0521vw, 1px);
$border-width-md: max(0.1042vw, 2px);
$address-width: max(5.2083vw, 100px);
$newsroom-width: max(15.625vw, 300px);
