@charset "utf-8";
@import "var", "mixins", "reset";

// 1920px 최적화
/* ------------------------------------------------------- Company Layout ------------------------------------------------------- */
.aimble-layout {
  width: 100vw;
  height: 100vh;
  background-color: $bg-light-gray;
  .header {
    width: 100%;
    background-color: transparent;
    border-bottom: $border-width solid rgba($color-white, 0.75);
    box-shadow: none;
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
  }
  .container-fluid {
    width: 100%;
    height: 100vh;
    .container {
      height: auto;
      min-height: calc(100vh - #{$footer-height});
      background-color: $color-white;
    }
    .footer {
      @include flex;
      justify-content: space-between;
      width: 100%;
      min-height: $footer-height;
      background-color: $color-dark-gray;
      padding: $gutter-xl $gutter-xxl;
      color: $color-gray;
      font-size: max(0.7292vw, 14px);
      font-weight: 300;
    }
  }
  .ps__rail-y {
    z-index: 99;
  }
}
.scroll-top {
  &.MuiFab-root {
    position: absolute !important;
    bottom: $gutter-xl;
    right: $gutter-xl;
    width: max(3.3333vw, 64px);
    height: max(3.3333vw, 64px);
    border-radius: $radius-mui;
    color: $color-white;
    background-color: rgba($primary-dark, 0.65);
    &:hover,
    &:active {
      background-color: rgba($primary-dark, 0.85);
    }
    .MuiSvgIcon-root {
      font-size: max(1.6667vw, 32px);
    }
  }
}
/*----------------------------------- Common > Header -----------------------------------*/
.header {
  &.extend {
    background-color: rgba($color-white, 0.25);
    backdrop-filter: blur(20px);
    opacity: 1;
    transition: height 0.1s ease-in-out, opacity 0.1s ease-in-out;
  }
  .MuiToolbar-root {
    height: auto;
    align-items: flex-start;
    padding: 0 $gutter-xxl;
  }
  h1 {
    .MuiLink-root {
      @include flex;
      width: max(7.8125vw, 150px);
      height: $header-height;
      .logo {
        width: 100%;
        height: auto;
      }
    }
  }
  .nav {
    @include flex;
    align-items: flex-start;
    ul {
      padding: 0;
    }
    .menu-list {
      @include flex;
      align-items: flex-start;
      .sub-menu {
        display: none;
      }
      &.on {
        .sub-menu {
          display: block;
          opacity: 1;
          transition: opacity 0.3s ease-in-out;
        }
      }
      .menu {
        @include flex;
        flex-direction: column;
        width: max(10.4167vw, 200px);
        padding: 0;
        .MuiListItemButton-root {
          width: max(10.4167vw, 200px);
        }
        .MuiTypography-root {
          color: rgba($color-white, 0.85);
          font-size: max(0.8333vw, 16px);
          letter-spacing: 0.075rem;
          text-align: center;
        }
        &-levels-1 {
          height: $header-height;
          &.Mui-selected {
            background-color: $secondary;
            & + .sub-menu {
              background-color: $secondary;
            }
          }
          .MuiTypography-root {
            text-transform: uppercase;
          }
        }
        &-levels-2 {
          height: max(4.1667vw, 80px);
          &.Mui-selected {
            background-color: transparent;
            .MuiTypography-root {
              color: rgba($color-white, 1);
              text-decoration: underline;
            }
          }
        }
      }
    }
    .language {
      @include flex;
      justify-content: center;
      align-items: center;
      height: $header-height;
      margin-left: $gutter-md;
      * {
        color: $color-white;
        border-color: rgba($color-white, 0.5);
      }
      .MuiFormControl-root {
        width: max(5.2083vw, 100px);
      }
      .MuiOutlinedInput-root {
        border-radius: $radius-xl;
        padding-left: max(0.7292vw, 14px);
        font-size: max(0.7292vw, 14px);
        .MuiInputAdornment-root + .MuiSelect-select {
          padding-left: 0;
          padding-right: max(1.6667vw, 32px);
        }
        .MuiOutlinedInput-notchedOutline {
          border-color: rgba($color-white, 0.5);
          padding: 0 $gutter-mui-sm;
        }
      }
    }
    .btn-menu {
      align-self: center;
      margin-left: $gutter-sm;
      display: none;
      .MuiSvgIcon-root {
        @include flex;
        justify-content: center;
        align-items: center;
        width: max(1.25vw, 24px);
        height: max(1.25vw, 24px);
      }
    }
  }
}
//Nav > Mobile
.MuiDrawer-root {
  &.nav-m {
    display: none;
  }
}
.MuiPaper-root {
  .MuiMenuItem-root {
    &.Mui-selected,
    &:hover {
      background-color: rgba($secondary-light, 0.25) !important;
    }
  }
}
/*----------------------------------- Common > Footer -----------------------------------*/
.footer {
  &-info {
    .footer-logo {
      width: max(5.2083vw, 100px);
      height: auto;
      opacity: 0.45;
    }
    dt,
    dd {
      display: inline-block;
      line-height: 1.8;
    }
    dt {
      color: $color-light-gray;
      font-weight: 400;
    }
    &-company {
      margin-top: $gutter-lg;
      dt,
      dd {
        padding-right: $gutter-md;
      }
    }
    &-address {
      dt {
        width: $address-width;
      }
      dd {
        width: calc(100% - #{$address-width});
      }
    }
    &-copy {
      padding-top: $gutter-xxl;
    }
  }
  &-nav {
    .MuiList-root {
      padding: 0;
      &.main-menu {
        @include flex;
        flex-direction: row;
        align-items: flex-start;
      }
      .MuiListItem-root {
        @include flex;
        flex-direction: column;
        padding: 0;
        .MuiListItemButton-root {
          width: max(6.25vw, 120px);
          padding: $gutter-xs;
          margin-left: $gutter-xxl;
        }
        .menu-levels-1 {
          .MuiTypography-root {
            text-transform: uppercase;
            color: $color-light-gray;
            font-weight: 600;
          }
        }
        .menu-levels-2 {
          .MuiListItemText-root {
            margin: 0;
          }
        }
      }
    }
  }
}
/*----------------------------------- Common > MUI Overriding -----------------------------------*/
.MuiSvgIcon-root {
  width: max(1.0938vw, 21px) !important;
  height: max(1.0938vw, 21px) !important;
}
.MuiInputBase-root {
  &.MuiOutlinedInput-root {
    border-radius: $radius-sm;
  }
  .MuiInputBase-inputSizeSmall {
    padding: max(0.4427vw, 8.5px) max(0.7292vw, 14px);
  }
  .MuiSelect-select {
    padding: max(0.4427vw, 8.5px) max(0.7292vw, 14px);
    border-radius: $radius-sm;
  }
  .MuiOutlinedInput-notchedOutline {
    legend {
      height: max(0.5729vw, 11px);
    }
  }
}
.MuiCheckbox-root {
  padding: max(0.4688vw, 9px) !important;
}
.MuiButtonBase-root {
  border-radius: $radius-sm;
  &.MuiButton-contained {
    color: $color-white;
    min-width: max(3.3333vw, 64px);
    padding: $gutter-mui-sm $gutter-mui-md;
  }
  &.MuiButton-containedGray,
  &.MuiButton-containedInherit {
    color: inherit;
  }
  .MuiButton-startIcon {
    .MuiSvgIcon-root {
      font-size: max(1.0417vw, 20px);
    }
  }
}
.MuiTooltip-tooltip {
  background-color: $color-tooltip !important;
  padding: $gutter-xs $gutter-sm !important;
  font-size: max(0.7292vw, 14px) !important;
}
.MuiTooltip-arrow::before {
  color: $color-tooltip !important;
}
.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialogTitle-root {
      font-size: max(1.0417vw, 20px);
      padding: $gutter-mui-md $gutter-md;
      & + .MuiDialogContent-root {
        padding-top: 0;
      }
    }
    .MuiDialogContent-root {
      padding: max(1.0417vw, 20px) $gutter-md;
    }
    .MuiDialogContentText-root {
      font-size: max(0.851vw, 16px);
    }
    .MuiDialogActions-root {
      padding: $gutter-mui-md;
      .MuiButtonBase-root {
        & + .MuiButtonBase-root {
          margin-left: $gutter-mui-sm;
        }
      }
    }
  }
}
/*----------------------------------- Page > Home -----------------------------------*/
.main-carousel {
  width: 100vw;
  max-height: max(41.6667vw, 800px);
  overflow: hidden;
  position: relative;
  .swiper {
    max-height: max(41.6667vw, 800px);
    height: 100%;
    .swiper-pagination-bullet {
      width: max(0.4167vw, 8px);
      height: max(0.4167vw, 8px);
      margin: 0 $gutter-xs;
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: max(2.2917vw, 44px);
    }
  }
  .carousel {
    @include flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &-img {
      width: 100%;
    }
    &-txt {
      @include flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: max(41.6667vw, 800px);
      color: $color-white;
      z-index: 98;
      .tit {
        font-size: max(3.125vw, 60px);
        font-weight: 700;
        letter-spacing: 0.05rem;
      }
      .subtit {
        font-size: max(2.8125vw, 54px);
        font-weight: 600;
        margin: $gutter-xl 0 $gutter-md;
      }
      .desc {
        max-width: 60%;
        font-size: max(1.25vw, 24px);
        font-weight: 300;
        margin: 0 auto;
      }
    }
  }
}
.home {
  //common
  .headline {
    .tit {
      display: block;
      font-size: max(1.25vw, 24px);
      font-weight: 700;
    }
    .desc {
      display: block;
      font-size: max(1.9792vw, 38px);
      font-weight: 700;
      letter-spacing: 0.025rem;
      line-height: 1.5;
      margin-top: $gutter-lg;
      &.lg {
        font-size: max(2.1875vw, 42px);
        font-weight: 700;
      }
      &.xl {
        font-size: max(2.5vw, 48px);
        font-weight: 800;
      }
    }
    & + .shortcut {
      margin-top: $gutter-xxl;
    }
  }
  .shortcut {
    width: max(11.4583vw, 220px);
    font-size: max(0.7813vw, 15px);
    font-weight: 400;
    border-radius: $radius-xl;
    padding: $gutter-md $gutter-lg;
  }
  //section
  &-technology {
    background-color: $color-white;
    padding: $gutter-xxl $gutter-xxxl;
    &-container {
      text-align: right;
      margin-top: -200px;
      .img {
        width: 75%;
        height: auto;
      }
    }
  }
  //section
  &-news {
    @include flex;
    justify-content: space-between;
    padding: $gutter-xxl 0 $gutter-xxl $gutter-xxxl;
    background: url(../img/main_newsroom.jpg) no-repeat center top / 100% auto;
    &-header {
      @include flex;
      flex-direction: column;
      align-self: stretch;
      width: $newsroom-width;
      flex-shrink: 0;
      color: $color-white;
      // .shortcut {
      //   display: none;
      // }
    }
    &-container {
      @include flex;
      flex-shrink: 0;
      width: calc(100vw - #{$newsroom-width} - #{$gutter-xxxl});
      padding: 0 $gutter-xxl;
      position: relative;
      .swiper-newsroom {
        position: unset;
        width: 100%;
        .swiper-slide {
          @include flex;
          flex-direction: column;
          justify-content: space-between;
          max-height: max(26.0417vw, 500px);
          border-radius: $radius-lg;
          background-color: $color-white;
          overflow: hidden;
          .txt-wrap {
            padding: $gutter-xxl $gutter-xl $gutter-xl;
            .tit {
              @include ellipsis(2, 1.6);
              font-size: max(1.3021vw, 25px);
              font-weight: 700;
              margin-top: $gutter-lg;
            }
            .desc {
              @include ellipsis(3, 1.6);
              font-size: max(0.9375vw, 18px);
              font-weight: 300;
              margin: $gutter-xl 0;
            }
            .date {
              color: $color-gray;
              margin-top: $gutter-lg;
            }
          }
          .btn {
            @include flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: max(5.2083vw, 100px);
            padding: $gutter-xl;
            background-color: rgba($color-light-gray, 0.45);
            border-radius: 0;
            span {
              font-size: max(0.9375vw, 18px);
              font-weight: 500;
              text-transform: uppercase;
            }
          }
        }
        .swiper-pagination {
          display: none;
        }
        .swiper-button-next,
        .swiper-button-prev {
          color: $color-white;
        }
      }
    }
  }
  //section
  &-partners {
    @include flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: max(31.25vw, 600px);
    background-color: $color-white;
    text-align: center;
    .headline {
      padding-bottom: $gutter-xxl;
      .desc {
        margin-bottom: $gutter-xl;
      }
    }
    .partners {
      @include flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      &-img {
        width: max(20.8333vw, 400px);
        height: auto;
      }
    }
  }
  //section
  &-contact {
    @include flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: max(26.0417vw, 500px);
    background: url(../img/main_contactus.jpg) no-repeat center top 40% / 120%
      auto;
    color: $color-white;
    text-align: center;
  }
}

/*----------------------------------- Common > Contents -----------------------------------*/
.contents {
  .inner {
    max-width: max(83.3333vw, 1600px);
    padding: $gutter-xxl;
    margin: 0 auto;
  }
  &.company {
    .contents-header {
      background: url(../img/bg_company.jpg) no-repeat center / cover;
    }
  }
  &.business {
    .contents-header {
      background: url(../img/bg_technology.png) no-repeat bottom / cover;
    }
  }
  &.notice,
  &.press-release,
  &.news-detail {
    .contents-header {
      background: url(../img/bg_newsroom.jpg) no-repeat center / cover;
    }
  }
  &.contact-us {
    .contents-header {
      background: url(../img/bg_contactus.jpg) no-repeat top / cover;
    }
  }
  //common
  &-header {
    width: 100%;
    height: max(20.8333vw, 400px);
    color: $color-white;
    .inner {
      @include flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      .category {
        font-size: max(0.9375vw, 18px);
        font-weight: 600;
        & + .tit {
          margin-top: $gutter-sm;
        }
      }
      .tit {
        font-size: max(2.8125vw, 54px);
        font-weight: 600;
        strong {
          display: inline-block;
          font-size: max(2.8125vw, 54px);
          font-weight: 600;
        }
        span {
          display: inline-block;
          font-size: max(2.3438vw, 45px);
          font-weight: 300;
        }
      }
    }
  }
  &-container {
    .tit {
      color: $color-title;
      font-size: max(2.3438vw, 45px);
      font-weight: 700;
      line-height: 1.5;
      small {
        display: block;
        color: $color-gray;
        font-size: max(1.25vw, 24px);
      }
    }
    .desc {
      strong {
        color: $color-dark-gray;
        font-size: max(1.7708vw, 34px);
        & + small {
          margin: $gutter-sm 0 $gutter-xl;
        }
      }
      small {
        color: $color-description;
        font-size: max(1.25vw, 24px);
      }
    }
  }
}
//Company
.company {
  .contents-container {
    text-align: center;
    .tit {
      margin: $gutter-sm 0 $gutter-xl;
    }
    .sub-tit {
      margin-top: $gutter-lg;
      color: $color-description;
      font-size: max(1.25vw, 24px);
      font-weight: 600;
      span {
        color: rgba($primary-dark, 0.85);
        font-size: max(1.25vw, 24px);
        font-weight: 700;
      }
    }
    .desc {
      max-width: 90%;
      margin: 0 auto $gutter-xxl;
      strong,
      span {
        color: $color-description;
        font-size: max(0.8333vw, 16px);
        line-height: 1.6;
      }
      strong {
        font-size: max(1.0417vw, 20px);
        font-weight: 600;
      }
    }
  }
  .team {
    @include flex;
    justify-content: space-between;
    text-align: left;
    & + .team {
      margin-top: $gutter-xxl;
    }
    &-info {
      width: calc(50% - #{$gutter-lg}* 2);
      margin: 0 $gutter-lg;
      flex-shrink: 0;
      &-img {
        width: max(20.8333vw, 400px);
        height: max(20.8333vw, 400px);
        background-color: rgba($color-light-gray, 0.5);
        border-radius: $radius-lg;
        margin-right: $gutter-lg;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &-name {
        font-size: max(1.9792vw, 38px);
        font-weight: 600;
        margin-left: 0;
        span {
          display: inline-block;
          color: rgba($primary-dark, 0.85);
          font-size: max(0.8333vw, 16px);
          font-weight: 600;
        }
      }
      &-list {
        border-top: $border-width solid rgba($color-gray, 0.75);
        padding: $gutter-md 0;
        margin-top: $gutter-lg;
        li {
          font-size: max(0.8333vw, 16px);
          line-height: 1.6;
          margin-top: $gutter-md;
          strong {
            display: block;
            color: rgba($color-dark-gray, 0.75);
            font-size: max(0.9375vw, 18px);
          }
        }
      }
    }
    dl {
      width: calc(25% - #{$gutter-lg}* 2);
      margin: 0 $gutter-lg;
      flex-shrink: 0;
      text-align: left;
      dt {
        color: rgba($color-dark-gray, 1);
        font-size: max(1.25vw, 24px);
        font-weight: 600;
        padding: $gutter-md 0;
        margin-bottom: $gutter-md;
        border-bottom: $border-width solid rgba($color-gray, 0.75);
      }
      dd {
        color: rgba($color-dark-gray, 1);
        font-size: max(1.25vw, 24px);
        line-height: 2.2;
      }
    }
  }
  .MuiTimeline-root {
    .MuiTimelineItem-root:first-child {
      .year {
        color: rgba($primary-dark, 0.85);
      }
      .MuiTimelineDot-root {
        background-color: rgba($primary-dark, 0.85);
      }
    }
    .MuiTimelineDot-root {
      border-width: max(0.1042vw, 2px);
      padding: max(0.2083vw, 4px);
    }
    .MuiTimelineSeparator-root {
      margin: 0 $gutter-xxl;
    }
    .MuiTimelineOppositeContent-root {
      width: max(20.8333vw, 400px);
      min-width: max(20.8333vw, 400px);
      .year {
        color: rgba($primary, 0.65);
        font-size: max(7.2917vw, 140px);
        font-weight: 700;
      }
    }
    .MuiTimelineContent-root {
      .date {
        color: $color-gray;
        font-size: max(1.0417vw, 20px);
        padding: $gutter-sm 0;
        &:first-child {
          padding-top: $gutter-xxl;
        }
      }
      .txt {
        color: $color-dark-gray;
        font-size: max(1.4583vw, 28px);
        font-weight: 700;
        padding-bottom: $gutter-xl;
      }
    }
  }
}
//Business
.business {
  .contents-container {
    //common
    .tit {
      text-align: center;
      margin: $gutter-lg 0 $gutter-xxl;
      line-height: 1.3;
    }
    .desc {
      color: $color-description;
      font-size: max(1.0417vw, 20px);
      line-height: 1.5;
    }
    dl {
      dt {
        color: $color-dark-gray;
        font-size: max(1.25vw, 24px);
        & + dd {
          margin-top: $gutter-lg;
        }
      }
      dd {
        color: $color-description;
        font-size: max(1.0417vw, 20px);
        line-height: 1.5;
        padding-left: max(1.5625vw, 30px);
        margin: $gutter-md 0;
        &::before {
          content: "";
          width: max(0.3125vw, 6px);
          height: max(0.3125vw, 6px);
          margin: 6px 12px;
          display: inline-block;
          border-radius: 50%;
          background-color: $color-description;
          margin-left: -18px;
        }
        & + dt {
          margin-top: $gutter-xl;
        }
      }
      &.service-steps {
        .img {
          width: max(15.625vw, 300px);
          height: auto;
        }
      }
    }
    ul {
      li {
        color: $color-description;
        font-size: max(1.0417vw, 20px);
        line-height: 1.5;
        padding-left: max(1.5625vw, 30px);
        margin: $gutter-md 0;
        &::before {
          content: "";
          width: max(0.3125vw, 6px);
          height: max(0.3125vw, 6px);
          margin: 6px 12px;
          display: inline-block;
          border-radius: 50%;
          background-color: $color-description;
          margin-left: -18px;
        }
      }
    }
    &.service {
      .article-wrap {
        @include flex;
        flex-wrap: wrap;
        align-items: center;
        .article {
          @include flex;
          width: calc(50% - #{$gutter-md}* 2);
          height: max(15.625vw, 300px);
          align-items: center;
          flex-shrink: 0;
          margin: $gutter-md;
          & + .article {
            margin-top: 0;
          }
          .img {
            width: max(10.4167vw, 200px);
            height: auto;
            margin-right: $gutter-xl;
          }
          .service-steps {
            dt {
              color: $color-dark-gray;
              font-size: max(1.4583vw, 28px);
              font-weight: 700;
              line-height: 1.5;
              & + dd {
                margin-top: $gutter-xl;
              }
            }
          }
        }
      }
    }
    &.platform {
      .article-wrap {
        @include flex;
        align-items: center;
        justify-content: space-between;
        margin: $gutter-xxl 0;
        &.col {
          flex-direction: column;
          align-items: flex-start;
          .article-img {
            width: 100%;
            margin: 0;
            img {
              width: 100%;
              height: auto;
            }
            & + .desc {
              margin-top: $gutter-xxl;
            }
          }
        }
        .article-img {
          flex-shrink: 0;
          width: 45%;
          margin: $gutter-xl $gutter-xl $gutter-xl 0;
          img {
            width: 100%;
            height: auto;
          }
        }
        .article {
          margin-left: $gutter-xl;
          &-tit {
            font-size: max(1.4583vw, 28px);
            font-weight: 700;
            line-height: 1.5;
            margin: $gutter-lg 0;
          }
        }
      }
    }
  }
}
//Newsroom > Notice
.notice {
  .contents-container {
    .MuiList-root {
      .MuiListItem-root {
        border-bottom: $border-width solid $color-light-gray;
        &:first-child {
          border-top: $border-width solid $color-dark-gray;
        }
        &:last-child {
          border-bottom: $border-width-md solid $color-dark-gray;
        }
      }
    }
    .MuiListItemButton-root {
      @include flex;
      align-items: center;
      padding: $gutter-xl 0;
      &:hover {
        .date {
          color: rgba($color-gray, 1);
        }
      }
    }
    .date {
      flex-shrink: 0;
      width: max(8.3333vw, 160px);
      color: rgba($color-gray, 0.5);
      text-align: center;
      strong {
        display: block;
        font-size: max(2.0833vw, 40px);
        font-weight: 700;
      }
      span {
        font-size: max(0.9375vw, 18px);
        font-weight: 500;
      }
    }
    .txt-wrap {
      padding: 0 $gutter-lg 0 $gutter-sm;
      .tit {
        @include flex;
        align-items: center;
        margin-top: $gutter-sm;
        .MuiChip-root {
          min-width: max(3.125vw, 60px);
          height: max(1.6667vw, 32px);
          border-radius: $radius-mui;
          & + strong {
            padding-left: $gutter-md;
          }
        }
        strong {
          @include ellipsis(1, 1.6);
          color: $color-dark-gray;
          font-size: max(1.25vw, 24px);
          font-weight: 600;
        }
        & + .desc {
          margin-top: $gutter-sm;
        }
      }
      .desc {
        @include ellipsis(1, 1.6);
        color: $color-gray;
        font-size: max(0.8333vw, 16px);
      }
    }
    .MuiPagination-root {
      margin-top: $gutter-xl;
      .MuiPagination-ul {
        justify-content: center;
        .MuiPaginationItem-root {
          min-width: max(2.0833vw, 40px);
          height: max(2.0833vw, 40px);
          border-radius: 50%;
          &.MuiPaginationItem-ellipsis {
            height: auto;
          }
        }
      }
    }
  }
}
//Newsroom > Press-release
.press-release {
  .contents-container {
    .card-wrap {
      @include flex;
      flex-direction: row;
      flex-wrap: wrap;
      .MuiCard-root {
        width: calc(25% - #{$gutter-md}* 2);
        height: max(26.0417vw, 500px);
        position: relative;
        border-radius: $radius-lg;
        margin: $gutter-md !important;
        .MuiCardActionArea-root {
          @include flex;
          flex-direction: column;
          justify-content: flex-start;
          height: 100%;
          .thumb {
            height: max(14.0625vw, 270px);
            background-color: rgba($color-light-gray, 0.5);
            & + .txt-wrap {
              padding-top: 0;
            }
          }
          .txt-wrap {
            width: 100%;
            padding: $gutter-xl;
            * {
              display: inline-block;
            }
            .tit {
              @include ellipsis(2, 1.5);
              font-size: max(1.1458vw, 22px);
              font-weight: 700;
              margin: $gutter-lg 0;
            }
            .desc {
              @include ellipsis(2, 1.5);
              font-size: max(0.9375vw, 18px);
              font-weight: 300;
            }
            .date {
              color: $color-gray;
              margin-top: $gutter-lg;
            }
          }
        }
      }
    }
  }
}
//Newsroom > Notice&Press-release > Common Detail
.news-detail {
  .contents-container {
    .view {
      border-top: $border-width solid $color-dark-gray;
      &-header {
        padding: $gutter-lg;
        border-bottom: $border-width solid $color-light-gray;
        .tit {
          @include ellipsis(1, 1.6);
          @include flex;
          align-items: center;
          .MuiChip-root {
            min-width: max(3.125vw, 60px);
            height: max(1.6667vw, 32px);
            border-radius: $radius-mui;
            & + strong {
              padding-left: $gutter-md;
            }
          }
          strong {
            @include ellipsis(1, 1.6);
            color: $color-dark-gray;
            font-size: max(1.25vw, 24px);
            font-weight: 600;
          }
          & + .MuiStack-root {
            margin-top: $gutter-md;
          }
        }
        .desc {
          * {
            color: $color-description;
            font-size: max(0.7813vw, 15px);
          }
          span {
            font-weight: 400;
            margin-left: $gutter-md;
          }
        }
        .MuiDivider-root {
          height: auto;
          border-color: rgba($color-gray, 0.75);
        }
      }
      &-content {
        padding: $gutter-lg;
        border-bottom: $border-width-md solid $color-dark-gray;
        .desc {
          color: $color-description;
          font-size: max(0.8854vw, 17px);
          line-height: 1.7;
        }
        .MuiTypography-caption {
          display: block;
          color: $color-gray;
          font-size: max(0.7813vw, 15px);
          word-break: break-all;
        }
        .img-wrap {
          @include flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          img {
            max-width: 100%;
            min-width: 50%;
          }
          .MuiTypography-caption {
            margin: $gutter-sm 0;
          }
        }
      }
      &-controls {
        margin-top: $gutter-md;
        .MuiButton-colorInherit {
          font-size: max(0.7813vw, 15px);
        }
        .MuiButton-outlined {
          width: max(11.4583vw, 220px);
          border-radius: $radius-xl;
          padding: $gutter-md $gutter-lg;
        }
      }
    }
  }
}
//Contact Us
.contact-us {
  .contents-container {
    .email {
      @include flex;
      align-items: center;
      padding: $gutter-xl 0 $gutter-xxl;
      color: rgba($color-dark-gray, 0.75);
      strong,
      span {
        font-size: max(1.0417vw, 20px);
      }
      strong {
        margin: 0 $gutter-md 0 $gutter-xs;
      }
    }
    .inquiry-list {
      @include flex;
      align-items: center;
      justify-content: center;
      .MuiCard-root {
        width: max(14.5833vw, 280px);
        height: max(14.5833vw, 280px);
        margin: $gutter-lg;
        border-radius: 50%;
        .MuiCardContent-root {
          @include flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          text-align: center;
          background-color: $color-background;
          .tit {
            color: $secondary;
            font-size: max(1.4583vw, 28px);
            font-weight: 600;
            margin-bottom: $gutter-sm;
          }
          .desc {
            color: $color-gray;
            font-size: max(1.1458vw, 22px);
          }
        }
      }
    }
    .address-list {
      dt {
        color: $color-dark-gray;
        font-size: max(1.7708vw, 34px);
        font-weight: 600;
        padding: $gutter-xl 0 $gutter-md;
        &:first-child {
          padding-top: 0;
        }
      }
      dd {
        color: $color-description;
        font-size: max(1.25vw, 24px);
        font-weight: 400;
        margin-bottom: $gutter-md;
      }
      .iframe-wrap {
        width: 100%;
        height: max(26.0417vw, 500px);
        overflow: hidden;
        border: $border-width solid $color-light-gray;
        iframe {
          width: 100%;
          height: 100%;
          border: 0;
        }
      }
    }
  }
}
@import "responsive";
